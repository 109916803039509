<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <!--<left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>-->
        <div class="content_section">
            <div class="content">
                <sub-title> 상세보기</sub-title>
                <div class="board_list">
                    <div class="board_detail">

                        <div class="bd_titles" v-if="item.type === sportsConst.BOARD_TYPE_FREE_BOARD">
                            <span class="bd_title">{{item.title}}</span>
                            <span class="bd_author">{{item.user.nickname}}</span>
                            <span class="bd_time">{{item.createTime|datef('MM/DD')}}</span>
                        </div>
                        <div class="bd_board_content" v-html="item.content"> </div>
                        <div class="bd_board_content" v-if="item.eventImg != null">
                            <img :src="item.eventImg" alt="이벤트이미지">
                        </div>

                    </div>
                </div>
                <div class="board_list" v-if="item.sportsBetInfoList">
                    <!--배팅내역 리스트-->
                    <sports-bet-list-comp
                            :bet-list="item.sportsBetInfoList"
                            :position="0"></sports-bet-list-comp>
                </div>
                <div class="board_list" v-if="item.leisureGameBetInfoList">
                    <leisure-bet-list-comp
                            :bet-list="item.leisureGameBetInfoList"
                            :position="0"></leisure-bet-list-comp>
                </div>
                <div class="go_back">
                    <button class="btn03" @click="goback()">리스트 보기</button>
                </div>
<!--                <CommentsComp :board-id="boardId"-->
<!--                              :comment-list="item.commentList"-->
<!--                              :commentable="item.commentable" @commentSuccess="commentSuccess()"></CommentsComp>-->
            </div>
        </div>

        <!-- right 메뉴 -->
        <!--<right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>
-->
        <foot-comp></foot-comp>
    </div>
</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {getBoardDetail, saveBoardComment} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import SportsBetListComp from "../../components/sports/SportsBetListComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import CommentsComp from "../../components/CommentsComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LeisureBetListComp from "../../components/leisuregame/LeisureBetListComp";

    export default {
        name: "BoardDetail",
        components: {
            LeisureBetListComp,
            SportsLeftBarComp,
            CommentsComp,
            RightBarBannerComp,
            FootComp, TopbarComp, LeisureLeftComp, LeftBarComp, SportsBetListComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                item: {user: {nickname: ''}, commentList: [], sportsBetInfoList: []},
                boardId: 0
            }
        },
        methods: {
            saveBoardComment() {
                saveBoardComment(this.boardComment).then(res => {
                    if (this.boardComment.content.trim() === '') {
                        this.$swal({
                            title: '댓글 내용을 입력하세요',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return false;
                    }
                    if (res.data.success) {
                        this.$swal({
                            title: '댓글 작성이 완료되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.content = ''
                        this.title = ''
                        this.$router.go(0)
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }

                })

            },
            commentSuccess() {
                this.getBoardDetail(this.boardId)
            },
            getBoardDetail() {
                getBoardDetail(this.boardId).then(res => {
                    this.item = {}
                    if (res.data.success) {
                        this.item = res.data.data
                        console.log(this.item)
                    }
                })
            },
            goback() {
                this.$router.go(-1)
            },
        },
        created() {
            this.boardId = this.$route.params.id
            this.getBoardDetail()
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    .bd_titles {
        border-bottom: 1px solid #f19f09;
    }

    .bd_titles span {
        overflow: hidden;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px !important;
    }

    .bd_titles .bd_title {
        width: 55%;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .bd_titles .bd_author {
        width: 25%;
    }

    .bd_titles .bd_time {
        width: 20%;
    }

    .bd_note .bd_title {
        color: #f19f09;
        font-weight: bold;
        text-align: center;
    }

    .bd_board_content {
        box-sizing: border-box;
        padding: 20px 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .go_banck {
        text-align: right;
        margin: 5px;
    }

    .go_banck button {
        width: 120px;
        font-size: 12px !important;
    }

    .comments {
        box-sizing: border-box;
        padding: 5px 5px;
    }

    .comments .comment_input {
        border: 1px solid #f19f09;
        background-color: #e0e0e0;
        width: 100%;
        height: 30px;
        color: #1c1c25;
        outline: none;
    }

    .comments lable {
        line-height: 30px;
    }

    .comments button {
        width: 100%;
        margin-top: 10px;
    }

    .comments span {
        line-height: 30px;
        border-bottom: 1px solid #2e313a;
        display: inline-block;
    }

    .comments .nickname {
        width: 20% !important;
        margin-left: 6px;
    }

    .comments .con {
        width: 50% !important;
        line-height: 30px;
        text-align: center;
    }

    .comments .time {
        width: 19%;
        text-align: right;
        margin-right: 6px;
    }
    .go_back{
        width: 100%;
        text-align: center;
    }
    .go_back button {
        width: 120px;
        font-size: 12px !important;
        margin: 10px 0;
        padding: 10px;
        background: #ffa500;
        color: #353539;
        font-weight: bold;
    }
</style>